<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="fas fa-laptop"
      icon-small
      color="primary"
      :title="$t('pageTitles.Page/SectionsList')"
    >
      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('forms.label') }}</th>
            <th>{{ $t('forms.icon') }}</th>
            <th class="text-right">
              {{ $t('forms.actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!sections || !sections.length">
          <tr>
            <td colspan="3">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <draggable
          v-else
          v-model="sections"
          tag="tbody"
          handle=".fa-arrows-alt"
          @change="sortHandler"
        >
          <tr
            v-for="section in sections"
            :key="`sections-list-${section.id}`"
          >
            <td>
              <i class="fas fa-arrows-alt"></i>
            </td>
            <td>{{ section.label }}</td>
            <td>
              <img
                v-if="section.homepage_icon"
                :src="section.homepage_icon.url"
                :alt="section.label"
                :title="section.label"
                class="section-preview"
              />
            </td>
            <td class="text-right table-actions">
              <app-btn
                color="error"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateSectionRemove(section)"
              >
                <v-icon
                  small
                  v-text="'fas fa-minus'"
                />
              </app-btn>
            </td>
          </tr>
        </draggable>
      </v-simple-table>
    </material-card>
    <v-dialog
      v-model="showRemovalDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('section_homepage_remove_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeRemovalDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="removeSection"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'SectionsList',

  mixins: [serverError],

  data: () => ({
    sections: [],
    showRemovalDialog: false,
    sectionToRemove: null,
  }),

  computed: {
    ...mapGetters({
      pageSections: 'page/getSections',
      processing: 'global/getProcessing',
    }),
  },

  created() {
    this.$store.commit('page/setSection', null);
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.sections = [];
      this.$store.dispatch('page/fetchSections')
        .then(() => {
          this.sections = this.pageSections.filter(section => section.is_on_homepage);
        });
    },
    sortHandler() {
      const order = this.sections.map(category => category.id);
      const payload = { order };
      this.$store.dispatch('page/reorderSections', payload)
        .then(response => {
          this.sections = response.data.data;
        })
        .catch(() => this.$toasted.error(this.$t('error_happened')));
    },
    initiateSectionRemove(section) {
      this.sectionToRemove = section.id;
      this.showRemovalDialog = true;
    },
    closeRemovalDialog() {
      this.sectionToRemove = null;
      this.showRemovalDialog = false;
    },
    removeSection() {
      const section = this.sections.find(s => s.id === this.sectionToRemove);
      if (section && !this.processing) {
        const payload = {
          ...section,
          is_on_homepage: false,
        }
        this.$store.dispatch('page/updateSection', payload)
          .then(() => {
            this.$toasted.success(this.$t('section_homepage_removed'));
            this.refreshData();
          })
          .catch(error => this.displayErrors(error))
          .finally(() => this.closeRemovalDialog());
      }
    },
  },
}
</script>

<style lang="scss">
.fa-arrows-alt {
  cursor: grab;
}

.section-preview {
  max-height: 40px;
}
</style>
